import React, { useCallback } from 'react';
import { ToastsV2 } from '@zola/zola-ui/src/components/ToastsV2';
import { hide } from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import { useAppSelector } from '~/reducers/useAppSelector';
import { useAppDispatch } from '~/reducers/useAppDispatch';

export const ToastsContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const toasts = useAppSelector((state) => state.toasts || []);
  const onRemoveToast = useCallback((id: number) => dispatch(hide(id)), [dispatch]);

  return <ToastsV2 toasts={toasts} onRemoveToast={onRemoveToast} />;
};

export default ToastsContainer;
